<template>
  <div>
    <!-- v-dialog baslıyor -->
    <v-app>
      <!-- v-dialog bitti -->

      <v-container>
        <div class="page-card">
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col>
                  <v-form v-model="isFormValid" ref="form">
                    <v-row>
                      <v-col md="6">
                        <v-row>
                          <v-col md="6">
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    required
                                    v-model="selected.name"
                                    label="Adı (*)"
                                    filled
                                    :rules="rules.name"
                                  ></v-text-field> </v-col
                              ></v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.username"
                                    label="Kullanıcı Adı (*)"
                                    filled
                                    :rules="rules.name"
                                  ></v-text-field
                                ></v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    label="Ülke Seçiniz..."
                                    v-model="selected.country"
                                    filled
                                    :items="countryitems"
                                    item-text="countryName"
                                    item-value="countryId"
                                    @change="getCity()"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    :items="centeritems"
                                    item-text="centerName"
                                    item-value="id"
                                    label=" Merkez Seçiniz"
                                    v-model="selected.centerid"
                                    filled
                                   
                                    @change="setCenterOrg"
                                    v-if="hideCenter"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.surname"
                                    label="Soyad (*)"
                                    filled
                                    :rules="rules.name"
                                  ></v-text-field
                                ></v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.email"
                                    label="E-posta (*)"
                                    filled
                                    :rules="rules.email"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    label="İl Seçiniz..."
                                    v-model="selected.city"
                                    filled
                                    :items="cityitems"
                                    item-text="cityName"
                                    item-value="cityId"
                                    @change="getDistrictItems()"
                                    no-data-text="Lütfen Önce Ülke Seçiniz"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    label="Bölüm Seçiniz"
                                    v-model="selected.specialtyDepartment"
                                    filled
                                    :items="specialtyDepartmentitems"
                                    item-text="text"
                                    item-value="value"
                                    v-if="hideCenter"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col class="mt-n11">
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    label="Organizasyon Seçiniz.."
                                    v-model="selected.organizationId"
                                    filled
                                    :items="organizationItems"
                                    item-text="organizationName"
                                    item-value="id"
                                    @change="setOrgCenter"
                                    v-if="hideOrg"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="6">
                        <v-row>
                          <v-col md="6">
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.phoneNumber"
                                    label="Cep Telefonu (*)"
                                    filled
                                    :rules="rules.counter"
                                    maxlength="10"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    :items="titleitems"
                                    label=" Ünvan Seçiniz "
                                    v-model="selected.title"
                                    filled                                   
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    label="İlçe Seçiniz..."
                                    v-model="selected.district"
                                    filled
                                    :items="districtitems"
                                    item-text="townName"
                                    item-value="townId"
                                    no-data-text="Lütfen Önce İlçe Seçiniz"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.landPhone"
                                    label="Sabit Telefon"
                                    filled
                                    maxlength="10"
                                    :rules="rules.phone"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    auto-select-first
                                    clearable
                                    v-model="selected.adminauthority"
                                    :items="adminauthorityitems"
                                    label="Yetki Seçiniz (*)"
                                    filled
                                    :rules="rules.authority"
                                  ></v-autocomplete>
                                </v-col>
                              </v-row>
                            </div>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-text-field
                                    v-model="selected.postCode"
                                    label="Posta kodu"
                                    filled
                                    :rules="rules.postcode"
                                    maxlength="5"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col>
                            <div class="form-group">
                              <v-row>
                                <v-col>
                                  <v-textarea
                                    v-model="selected.address"
                                    label="Adres"
                                    filled
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="2 ml-auto">
                  <button
                    id="btnSave"
                    type="button"
                    class="btn btn-primary"
                    @click="send"
                    :disabled="!isFormValid"
                  >
                    Kaydet
                  </button>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "users",
  data() {
    return {
      isFormValid: false,
      globaladmin: false,
      cro: false,
      user: false,
      //date: new Date().toISOString().substr(0, 10),
      menu: false,
      rules: {
        name: [(val) => !!val || "Bu alan doldurulmalı"],
        authority: [(val) => !!val || "Bu alan doldurulmalı"],
        counter: [
          (v) => {
            var regex = /[0-9]{10}/;
            return (
              regex.test(v) ||
              "Lütfen Telefon Numarasını 10 Haneli Olarak Giriniz"
            );
          },
        ],
        phone: [
          (v) => {
            var regex = /[0-9]{10}/;
            return (
              regex.test(v) || v.length == 0 || "Geçersiz Telefon Numarası"
            );
          },
        ],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return pattern.test(v) || "Lütfen Geçerli E-posta Adresi Giriniz.";
          },
        ],
      },

      selected: {
        name: "",
        surname: "",
        username: "",
        adminauthority: "",
        title: "",
        centerid: "",
        specialtyDepartment: "",
        organizationId: "",
        phoneNumber: "",
        landPhone: "",
        email: "",
        country: "",
        city: "",
        district: "",
        postCode: "",
        address: "",
      },
      adminauthorityitems: [],
      titleitems: [
        { text: "Prof Dr.", value: "Prof. Dr." },
        { text: "Doç Dr.", value: "Doç Dr." },
        { text: "Uzm. Dr.", value: "Uzm. Dr." },
        { text: "Dr.", value: "Dr." },
        { text: "Yrd. Doç. Dr.", value: "Yrd. Doç. Dr." },
        { text: "Dr. Öğr. Üyesi", value: "Dr. Öğr. Üyesi" },
      ],
      centeritems: [],
      countryitems: [],
      cityitems: [],
      districtitems: [],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
      organizationItems: [],
      temp: null,
      hideOrg: true,
      hideCenter: true,
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Kullanıcı Ekle", route: "/users" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/userlist" },
        { title: "Kullanıcı Ekle" },
      ]);
    }

    this.globaladmin = this.isGlobalAdmin;
    this.cro = this.isCro;
    this.user = this.isNormalUser;
    this.isShow = this.globaladmin;
    this.getCountryItems();
    this.getCenterItems();
    this.getOrganization();
    this.fillAdminAuthorityItems();
  },
  methods: {
    setOrgCenter() {
      if (this.selected.organizationId != null) {
        this.hideCenter = false;
      } else {
        this.hideCenter = true;
      }
    },
    setCenterOrg() {
      if (this.selected.centerid != null) {
        this.hideOrg = false;
      } else {
        this.hideOrg = true;
      }
    },
    clearFields() {
      this.selected.name = "";
      this.selected.surname = "";
      this.selected.username = "";
      this.selected.adminauthority = "";
      this.selected.title = "";
      this.selected.centerid = "";
      this.selected.specialtyDepartment = "";
      this.selected.organizationId = "";
      this.selected.phoneNumber = "";
      this.selected.landPhone = "";
      this.selected.email = "";
      this.selected.country = "";
      this.selected.city = "";
      this.selected.district = "";
      this.selected.postCode = "";
      this.selected.address = "";
    },
    fillAdminAuthorityItems() {
      if (this.globaladmin) {
        this.adminauthorityitems = [
          { text: "Normal User", value: 3 },
          { text: "CRO", value: 1 },
          { text: "Global Admin", value: 2 },
        ];
      } else {
        this.adminauthorityitems = [{ text: "Normal User", value: 3 }];
      }
    },
    send() {
      // if (this.selected.adminauthority == 3) {
      //   this.selected.adminauthority = 0;
      // }
      //console.log("Giden yeni kayıt", this.selected);
      document.getElementById('btnSave').disabled = true;
      ApiService.post("/user/new", this.selected)
        .then(({ data }) => {
          console.log("yeni kayıt eklendi", data);
          Swal.fire({
            icon: "success",
            title: "Kayıt işlemi Başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
          this.$router.push({ name: "userlist" });
        })
        .catch((err) => {
          document.getElementById('btnSave').disabled = false;
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
          this.countryitems = data;
          
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity() {
    
      this.selected.city = "";
      this.selected.district = "";
      if (this.selected.country == null) {
        this.cityitems = [];
        this.districtitems = [];
      }
      ApiService.get("cities", this.selected.country)
        .then(({ data }) => {
          this.cityitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItems() {
      this.selected.district = "";
      if (this.selected.city == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", this.selected.city)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCenterItems() {
      ApiService.get("centers")
        .then(({ data }) => {
          this.centeritems = data.result;
          //console.log("centeritem geldi", this.centeritems);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getOrganization() {
      ApiService.get("organizations")
        .then(({ data }) => {
          this.organizationItems = data.result;
          //console.log("organization itemleri geldi", this.organizationItems);
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    ...mapGetters(["isGlobalAdmin", "isCro", "isNormalUser"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
